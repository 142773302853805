.navbar-nav_bar{
    height: 100%;
    width: 100%;
    background: #100f1b;
    z-index: 4;
    display: flex;
    flex-direction: column;
    border-right: 2px #F1DAC4 solid;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.navbar-nav_bar.notLoaded{
    opacity: 0;
}

.navbar-nav_bar_toggle{
    display: none;
    position: absolute;
}

.navbar-nav_item{
    padding: 15px 8px;
    color: #FFF;
    font-weight: bold;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 14px;
    border-bottom: 2px #F1DAC4 solid;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.navbar-nav_item:hover{
    color: #100f1b;
    background: #F1DAC4;
}

.active_navbar_item{
    color: #100f1b;
    background: #F1DAC4;
}

.navbar-go_home:hover{
  background: #b4401d;
  color: #F1DAC4;
}

@media screen and (max-width: 1100px){
    .navbar-nav_bar {
      position: fixed;
      border: 2px #F1DAC4 solid;
      width: 300px;
      left: 0px;
      overflow-y: scroll;
    }
  
    .navbar-nav_bar::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    
    .navbar-nav_bar::-webkit-scrollbar-thumb {
      background-color: #F1DAC4;
    }
    
    .navbar-nav_bar::-webkit-scrollbar-thumb:hover {
      background-color: #F1DAC4;
    }
  
    .navbar-nav_bar.hidden {
      left: -300px;
    }
  
    .navbar-nav_bar_toggle{
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      display: inline;
      position: fixed;
      color: #F1DAC4;
      font-size: 22px;
      z-index: 5;
      left: 300px;
      top: 0;
      background: #100f1b;
      padding: 8px;
      border: 2px #F1DAC4 solid;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
  
    .navbar-nav_bar_toggle.hidden {
      left: 0px;
    }
}  

@media screen and (max-width: 760px){
    .navbar-nav_bar{
      width: 250px;
      left: 0px;
    }
  
    .navbar-nav_bar.hidden {
      left: -250px;
    }
  
    .navbar-nav_bar_toggle{
      left: 250px;
    }
  
    .navbar-nav_bar_toggle.hidden {
      left: 0px;
    }
  }
  