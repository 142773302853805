.react-clock-wrapper {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.react-clock-title-wrapper {
  text-align: center;
  color: #FFF;
  font-family: 'IBM Plex Mono', monospace;
}

.react-clock-break-session-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.react-clock-break-wrapper,
.react-clock-session-wrapper {
  display: flex;
  flex-direction: column;
  color: #FFF;
  font-family: 'IBM Plex Mono', monospace;
  justify-self: center;
}

#react-clock-break-label,
#react-clock-session-label {
  margin-bottom: 10px;
}

.react-clock-break-counter-wrapper,
.react-clock-session-counter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'IBM Plex Mono', monospace;
}

.react-clock-break-session-wrapper button {
  font-size: 14px;
  padding: 8px 18px;
  background: #F1DAC4;
  border: 2px transparent solid;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.react-clock-break-session-wrapper button:hover {
  background: transparent;
  border-color: #F1DAC4;
  color: #F1DAC4;
}

.react-clock-clock-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #F1DAC4;
  width: 200px;
  margin: 20px auto;
  font-family: 'IBM Plex Mono', monospace;
}

#react-clock-timer-label {
  margin-top: 10px;
  margin-bottom: 0;
}

#react-clock-timer-left {
  margin: 10px auto;
}

.react-clock-clock-btns-wrapper {
  display: flex;
  column-gap: 10px;
}

.react-clock-clock-btns-wrapper button{
  padding: 8px 16px;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: bold;
  cursor: pointer;
  color: #F1DAC4;
  background: #100f1b;
  border: 2px transparent solid;
  transition: all 0.2s ease-in-out  ;
}

.react-clock-clock-btns-wrapper button:hover{
  color: #100f1b;
  background: #F1DAC4;
  border-color: #100f1b;
}

@media screen and (max-width: 500px) {
  .react-clock-title-wrapper,
  .react-clock-break-session-wrapper{
    transform: scale(0.8);
  }

  .react-clock-break-session-wrapper button{
    font-size: 18px;
  }
}