#heatmap-wrapper {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  color: #FFF;
  text-align: center;
  font-family: 'Arial';
}

#heatmap-title {
  text-align: center;
  margin-bottom: 20px;
}

#heatmap-title h1,
#heatmap-title h3 {
  margin: 0;
  font-family: "Arial";
  font-weight: 400;
}

.heatmap-visHolder {
  position: relative;
  margin-bottom: 30px;
}

#heatmap-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(21, 21, 21, 0.8);
  color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  position: absolute;
  font-size: 14px;
  font-family: "Arial";
  padding: 10px;
}

@media screen and (max-width: 1600px){
  #heatmap-wrapper {
      transform: translateX(-100px);
  }
  #heatmap-title,
  #heatmap-description{
      transform: scale(0.9);
  }
  .heatmap-visHolder {
      transform: scale(0.8);
  }
}

@media screen and (max-width: 1350px){
  #heatmap-wrapper {
      transform: translateX(-150px);
  }
  #heatmap-title,
  #heatmap-description{
      transform: scale(0.8);
  }
  .heatmap-visHolder {
      transform: scale(0.7);
  }
}

@media screen and (max-width: 1250px){
  #heatmap-wrapper {
      transform: translateX(-220px);
  }
  #heatmap-title,
  #heatmap-description{
      transform: scale(0.8);
  }
  .heatmap-visHolder {
      transform: scale(0.6);
  }
}

@media screen and (max-width: 1100px){
  #heatmap-wrapper {
      transform: translateX(-170px);
  }
  #heatmap-title,
  #heatmap-description{
      transform: scale(0.8);
  }
  .heatmap-visHolder {
      transform: scale(0.7);
  }
}


@media screen and (max-width: 900px){
  #heatmap-wrapper{
    transform: translateY(-330px) !important;
    min-height: 1400px;
  }
  
  #heatmap-title,
  #heatmap-description{
      transform: scale(0.6) translateX(-650px);
  }
  #heatmap-wrapper {
      transform: translateX(0);
  }
  .heatmap-visHolder {
      transform: scale(1) rotate(90deg) translateX(400px) translateY(400px);
      margin-bottom: 100px;
  }

  #heatmap-legend{
    transform: translateY(700px) translateX(-400px);
  }
}

@media screen and (max-width: 500px){
  #heatmap-wrapper{
    transform: translateY(-330px) !important;
    min-height: 1300px;
  }
  
  #heatmap-title{
    font-size: 20px;
    width: 350px;
    transform: translateX(-425px) translateY(100px);
  }
  #heatmap-description{
      font-size: 16px;
      width: 350px;
      transform: translateX(-425px) translateY(100px);
  }
  #heatmap-wrapper {
      transform: translateX(0);
  }
  .heatmap-visHolder {
      transform: scale(0.8) rotate(90deg) translateX(500px) translateY(550px);
      margin-bottom: 100px;
  }

  #heatmap-legend{
    transform: translateY(600px) translateX(-425px);
  }
}