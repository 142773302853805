.react-markdown-wrapper {
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-column-gap: 10px;
    padding: 0 20px 20px 20px;
    font-family: 'IBM Plex Mono', monospace;
}


#react-markdown-editor-header,
#react-markdown-preview-header {
    background: #161B33;
    padding: 5px 10px;
    font-weight: bold;
    border-bottom: 2px #F1DAC4 solid;
    color: #F1DAC4;
}

#react-markdown-textarea-wrapper {
    width: auto;
    min-height: 300px;
    display: grid;
    grid-template-rows: 30px 1fr;
    border: 2px #F1DAC4 solid;
}

.react-markdown-wrapper textarea {
    min-height: 200px;
    padding: 20px;
    background: rgba(255, 239, 254, 1);
    border: #222222 1px solid;
    resize: vertical;
    box-shadow: -4px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -4px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -4px 3px 10px 0px rgba(0, 0, 0, 0.75);
}

#react-markdown-preview-wrapper {
    width: auto;
    min-height: 300px;
    display: grid;
    grid-template-rows: 30px 1fr;
    border: 2px #F1DAC4 solid;
    overflow-x: scroll;
}

#react-markdown-preview {
    padding: 10px 30px;
    background: rgba(255, 239, 254, 1);
    border: #222222 1px solid;
    box-shadow: -4px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -4px 3px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -4px 3px 10px 0px rgba(0, 0, 0, 0.75);
}

#react-markdown-preview-wrapper::-webkit-scrollbar,
.react-markdown-wrapper textarea::-webkit-scrollbal {
    width: 6px;
    height: 6px;
}
  
#react-markdown-preview-wrapper::-webkit-scrollbar-thumb,
.react-markdown-wrapper textarea::-webkit-scrollbar-thumb  {
    background-color: #F1DAC4;
}

#react-markdown-preview-wrapper::-webkit-scrollbar-thumb:hover,
.react-markdown-wrapper textarea::-webkit-scrollbar-thumb:hover  {
    background-color: #F1DAC4;
}

@media screen and (max-width: 1200px){
    .react-markdown-wrapper {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }
}

@media screen and (max-width: 450px){
    .react-markdown-wrapper {
        width: calc(100% - 20px);
        padding: 0 10px 10px 10px;
    }
    
}