.d3-wrapper{
    background-image: linear-gradient(to right top, #0d0c1d, #100f1b, #121119, #141317, #151515);
    width: auto;
    min-height: calc(100vh - 58px);
    height: auto;
    padding: 29px;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns: 300px 1fr;
}

.d3-wrapper:after{
  position: absolute;
  content: '';
  width: calc(100% - 58px);
  height: calc(100% - 58px);
  left: 0;
  right: 0;
  top: 0;
  margin: 25px auto;
  border: #F1DAC4 4px solid;
}

.d3-particles_background{
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
}

.d3-introduction{
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  padding: 25px;
  color: #FFF;
  font-family: 'IBM Plex Mono', monospace;
}

.d3-certificate_wrapper{
  color: #0d0c1d;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 16px;
  background: #F1DAC4;
  width: 220px;
  margin: 0;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 20px;
  align-items: center;
  place-content: start;
  padding: 12px 18px;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.d3-certificate_wrapper img{
  width: auto;
  height: 40px;
}

.d3-certificate_wrapper:hover{
  transform: scale(1.05);
}

.d3-plot_wrapper{
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #100f1b;
}

@media screen and (max-width: 1100px){
  .d3-wrapper{
      grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 760px){
  .d3-wrapper{
    padding: 10px;
    min-height: calc(100vh - 20px);
  }

  .d3-wrapper:after{
    width: calc(100% - 18px);
    height: calc(100% - 18px);
    margin: 5px auto;
    z-index: 3;
  }
}
