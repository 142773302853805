#treemap-wrapper {
  width: 100%;
  height: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Arial";
  color: #fff;
}

#treemap-title {
  margin-bottom: 10px;
}

#treemap-description {
  font-size: 16px;
  margin-top: 0;
}

.treemap-visHolder {
  margin-top: 30px;
  transition: all 0.2s ease-in-out;
}

#treemap-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(21, 21, 21, 0.8);
  color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  position: absolute;
  font-size: 14px;
  font-family: "Arial";
  padding: 10px;
}

#treemap-legend {
  width: 400px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 80px);
  margin-top: 15px;
  transition: all 0.2s ease-in-out;
}

.treemap-legend-item {
  display: flex;
  margin: 5px 0;
}

.treemap-square-svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

@media screen and (max-width: 1380px){
  #treemap-wrapper {
    justify-content: start;
    align-items: start;
  }
  
  #treemap-title,
  #treemap-description{
    margin-left: 25px;
    text-align: left;
  }

  #treemap-legend{
    transform: translateX(50px) translateY(-50px);
  }

  .treemap-visHolder{
    transform: scale(0.9) translateX(-30px) translateY(-30px);
  }
}

@media screen and (max-width: 1300px){
  #treemap-legend{
    transform: translateX(50px) translateY(-100px);
  }

  .treemap-visHolder{
    transform: scale(0.8) translateX(-100px) translateY(-60px);
  }
}

@media screen and (max-width: 1200px){
  #treemap-legend{
    transform: translateX(30px) translateY(-150px);
  }
  .treemap-visHolder{
    transform: scale(0.7) translateX(-180px) translateY(-100px);
  }
}

@media screen and (max-width: 1100px){
  #treemap-legend{
    transform: translateX(25px) translateY(0);
  }
  .treemap-visHolder{
    transform: scale(0.9) translateX(-30px);
  }
}
@media screen and (max-width: 1000px){
  #treemap-legend{
    transform: translateX(25px) translateY(-130px);
  }
  .treemap-visHolder{
    transform: scale(0.7) translateX(-180px) translateY(-100px);
  }
}
@media screen and (max-width: 800px){
  #treemap-wrapper{
    min-height: 1150px;
  }
  #treemap-legend{
    transform: translateX(25px) translateY(400px);
  }
  .treemap-visHolder{
    transform: rotate(90deg) scale(0.9) translateX(200px) translateY(280px);
  }
}
@media screen and (max-width: 550px){
  #treemap-title,
  #treemap-description{
    transform: scale(0.8);
    margin-left: -10px;
  }

  #treemap-wrapper{
    min-height: 950px;
  }
  #treemap-legend{
    transform: translateX(25px) translateY(220px);
  }
  .treemap-visHolder{
    transform: rotate(90deg) scale(0.7) translateX(150px) translateY(430px);
  }
}