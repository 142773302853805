#react-quote-machine-quote-box {
    max-width: 500px;
    min-width: 250px;
    width: auto;
    height: auto;
    margin: 0 20px;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    text-align: center;
    background: #F1DAC4;
    color: #161B33;
    font-family: 'IBM Plex Mono', monospace;
    justify-self: center;
    align-self: start;
}

#react-quote-machine-text {
    font-size: 18px;
    font-weight: 500;
}

#react-quote-machine-author {
    font-weight: 400;
    text-align: right;
}

#react-quote-machine-new-quote,
#react-quote-machine-tweet-quote{
    padding: 10px;
    background: #161B33;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 12px;
    display: grid;
    place-content: center;
}

#react-quote-machine-tweet-quote{
    text-decoration: none;
    outline: none;
}

@media screen and (max-width: 700px){
    #react-quote-machine-quote-box {
        padding: 15px;
    }
}

@media screen and (max-width: 450px){
    #react-quote-machine-quote-box {
        margin: 0 auto;
        width: 230px;
    }
}