#choropleth-wrapper {
    color: #FFF;
    width: 100%;
    height: 100%;
    margin-top: 25px;
    margin: 25px 25px 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Arial';
    transition: all 0.2s ease-in-out;
}

#choropleth-description {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin: 10px 0;
}

.choropleth-visHolder {
    margin-top: -40px;
    display: grid;
    place-items: center;
    transition: all 0.2s ease-in-out;
}

#choropleth-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(21, 21, 21, 0.8);
    color: #fff;
    border: 1px solid #000;
    border-radius: 10px;
    position: absolute;
    font-size: 14px;
    font-family: 'Arial';
    padding: 10px;
}

#choropleth-legend {
    display: flex;
}

.choropleth-legend-item {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 10px;
}

.choropleth-legend-item span {
    margin-right: -10px;
    margin-top: 5px;
}

.choropleth-square-svg {
    width: 40px;
    height: 20px;
}

@media screen and (max-width: 1350px){
    #choropleth-wrapper {
        transform: translateX(-20px);
    }
    #choropleth-title,
    #choropleth-description{
        transform: scale(0.9);
    }
    .choropleth-visHolder {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 1200px){
    #choropleth-wrapper {
        transform: translateX(-70px);
    }
    .choropleth-visHolder {
        transform: scale(0.7);
    }
}

@media screen and (max-width: 1100px){
    #choropleth-wrapper {
        transform: translateX(0);
    }
    .choropleth-visHolder {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 950px){
    #choropleth-wrapper {
        transform: translateX(-100px);
    }
    .choropleth-visHolder {
        transform: scale(0.7);
    }
}

@media screen and (max-width: 800px){
    #choropleth-title,
    #choropleth-description{
        transform: scale(0.7);
    }
    #choropleth-wrapper {
        transform: translateX(-250px);
    }
    .choropleth-visHolder {
        transform: scale(0.5) translateY(-250px);
    }
}

@media screen and (max-width: 570px){
    #choropleth-title{
        font-size: 24px;
        width: 350px;
        text-align: center;
    }
    #choropleth-description{
        font-size: 18px;
        width: 350px;
        text-align: center;
    }
    #choropleth-wrapper {
        transform: translateX(-370px);
    }
    .choropleth-visHolder {
        transform: scale(0.5) translateY(-100px) rotate(90deg);
    }
}
