.website-projects_wrapper{
    background-image: linear-gradient(to right top, #0d0c1d, #100f1b, #121119, #141317, #151515);
    width: auto;
    min-height: calc(100vh - 58px);
    height: auto;
    padding: 29px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.website-projects_wrapper:after{
  position: absolute;
  content: '';
  width: calc(100% - 58px);
  height: calc(100% - 58px);
  left: 0;
  right: 0;
  top: 0;
  margin: 25px auto;
  border: #F1DAC4 4px solid;
  z-index: 0;
}

.website-projects-particles_background{
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
}

.website-projects_introduction{
    padding: 25px;
    width: auto;
    color: #FFF;
    font-family: 'IBM Plex Mono', monospace;
    z-index: 3;
    position: relative;
    text-align: center;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.website-projects_introduction.notLoaded{
    opacity: 0;
}


.website-projects_grid{
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 20px;
    padding: 20px;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.website-projects_grid.notLoaded{
    opacity: 0;
    transform: translateY(50px);
}

.website-projects_grid_item{
    width: auto;
    background: #F1DAC4;
    border: 4px #F1DAC4 solid;
    cursor: pointer;
    padding: 10px;
    transition: all 0.3s ease-in-out;
}


.website-projects_grid_item:hover{
    background: #100f1b;
    color: #F1DAC4;
}

 .website-projects_grid_item_cover{
    width: 100%;
 }

 .website-projects_data{
    font-family: 'IBM Plex Mono', monospace;
    font-weight: bolder;
    padding: 10px 5px;
 }

 @media screen and (max-width: 760px){
    .website-projects-wrapper{
      padding: 10px;
      min-height: calc(100vh - 20px);
    }
  
    .website-projects-wrapper:after{
      width: calc(100% - 18px);
      height: calc(100% - 18px);
      margin: 5px auto;
    }
  }
  

@media screen and (max-width: 550px){
    .website-projects_grid{
        position: relative;
        z-index: 3;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 20px;
        padding: 20px;
        opacity: 1;
        transform: translateY(0);
        transition: all 0.3s ease-in-out;
    }

    .website-projects_grid_item{
        border: 2px #F1DAC4 solid;
        padding: 5px;
        font-size: 12px;
    }
}