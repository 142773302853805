#scatterplot-wrapper {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  color: #FFF;
}

#scatterplot-title {
  text-align: center;
}

#scatterplot-title h1,
#scatterplot-title h3 {
  margin: 0;
  font-family: "Arial";
  font-weight: 400;
}

.scatterplot-visHolder {
  position: relative;
}

#scatterplot-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #a7aefc;
  border: 1px solid #000;
  border-radius: 10px;
  position: absolute;
  font-size: 12px;
  font-family: "Arial";
  padding: 10px;
}

@media screen and (max-width: 1280px){
    #scatterplot-wrapper{
        align-items: start;
    }

    #scatterplot-title h1,
    #scatterplot-title h3 {
      margin-left: 25px;
    }

    .scatterplot-visHolder{
        transform: scale(0.8) translateX(-100px);
    }
}
@media screen and (max-width: 800px){
    #scatterplot-wrapper{
        align-items: start;
    }

    #scatterplot-title h1,
    #scatterplot-title h3 {
      transform: scale(0.8);
    }

    .scatterplot-visHolder{
        transform: scale(0.7) translateX(-200px) translateY(-100px);
    }
}
@media screen and (max-width: 700px){
    #scatterplot-wrapper{
        justify-self: start;
        align-items: start;
        min-height: 700px;
    }

    #scatterplot-title h1,
    #scatterplot-title h3 {
      transform: scale(0.6) translateY(-100px) translateX(-170px);
    }

    .scatterplot-visHolder{
        transform: rotate(90deg) scale(0.7) translateX(0px) translateY(400px);
    }
}