.Codepen-wrapper{
    width: 280px;
    height: 30px;
    background: #F1DAC4;
    margin: 20px auto 0 20px;
    padding: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.Codepen-wrapper:hover{
    transform: scale(0.95);
}

.Codepen-wrapper img{
    height: 20px;
    width: auto;
    border: #100f1b 2px solid;
    padding: 4px;
}

.Codepen-wrapper p{
    color: #100f1b;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 14px;
    margin-right: 2px;
}

@media screen and (max-width: 450px){
    .Codepen-wrapper{
        width: 250px;
        height: 25px;
        margin: 10px auto 0 10px;
    }

    .Codepen-wrapper img{
        height: 15px;
        width: auto;
        border: #100f1b 2px solid;
        padding: 4px;
    }

    .Codepen-wrapper p{
        font-size: 12px;
        font-weight: 700;
    }

}