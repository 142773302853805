.wrapper{
    background-image: linear-gradient(to right top, #0d0c1d, #100f1b, #121119, #141317, #151515);
    width: auto;
    min-height: 100vh;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper:after{
  position: absolute;
  content: '';
  width: calc(100% - 50px);
  height: 100%;
  left: 0;
  right: 0;
  margin: 25px auto 0 auto;
  border: #F1DAC4 4px solid;
}

#border{
  position: absolute;
  width: 100dvw;
  transform: scale(0.95);
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: #F1DAC4 4px solid;
}

.learn_btn{
  text-decoration: none; 
  font-family: 'IBM Plex Mono', monospace;
  position: absolute;
  top: 50px;
  right: 50px;
  background: #F1DAC4;
  color: #0D0C1D;
  font-weight: 600;
  padding: 8px 26px;
  z-index: 3;
  cursor: pointer;
  border: #F1DAC4 2px solid;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.2s ease-in-out;
  font-style: none;
}

.learn_btn.notLoaded{
  transform: translateY(20px);
  opacity: 0;
}

.learn_btn:hover{
  color: #F1DAC4;
  background: transparent;
}

#tooltip{
  position: absolute;
  max-width: 150px;
  background: #F1DAC4;
  font-size: 12px;
  z-index: 3;
  padding: 8px;
  border: #0D0C1D 2px solid;
  border-radius: 6px;
  font-family: 'IBM Plex Mono', monospace;
  opacity: 0;
  transition: all 100ms ease-in-out;
}

.wrapper div{
    z-index: 2;
}


#landing_text_wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  transition: all 100ms ease-in-out;
}

.Title{
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 5px;
    color: #F1DAC4;
    transition: all 0.3s ease-in-out;

    transform: translateY(0);
    opacity: 1;
}

.Title.notLoaded{
  transform: translateY(20px);
  opacity: 0;
}

.Position{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #A69CAC;
  transform: translateY(0);
  opacity: 1;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.Position.notLoaded{
  transform: translateY(20px);
  opacity: 0;
}

.skills_wrapper{
  font-family: 'IBM Plex Mono', monospace;
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  width: 600px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-row-gap: 10px;
  place-items: center;
  transition: all 100ms ease-in-out;
}


.skill{
  position: relative;
  cursor: default;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.skill.notLoaded{
  opacity: 0;
}

.skills_wrapper > .skill:nth-child(1) { transition-delay: 0.1s; }
.skills_wrapper > .skill:nth-child(2) { transition-delay: 0.2s; }
.skills_wrapper > .skill:nth-child(3) { transition-delay: 0.3s; }
.skills_wrapper > .skill:nth-child(4) { transition-delay: 0.4s; }
.skills_wrapper > .skill:nth-child(5) { transition-delay: 0.5s; }
.skills_wrapper > .skill:nth-child(6) { transition-delay: 0.6s; }
.skills_wrapper > .skill:nth-child(7) { transition-delay: 0.7s; }
.skills_wrapper > .skill:nth-child(8) { transition-delay: 0.8s; }
.skills_wrapper > .skill:nth-child(9) { transition-delay: 0.9s; }
.skills_wrapper > .skill:nth-child(10) { transition-delay: 1s; }

.skill::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #F1DAC4;
  transform: scaleX(0);
  transform-origin: bottom;
  transition: transform 0.3s ease;
}

.skill:hover::after {
  transform: scaleX(1);
}

.particles_background{
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
}

.figure_space{
  width: 100%;
  height: auto;
  display: flex;
  height: 400px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.jupiter_picture{
    width: 600px;
    height: 600px;
    transform: translateY(180px);
    opacity: 1;
    overflow: hidden;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

.jupiter_picture.notLoaded{
  bottom: -450px;
  opacity: 0;
}


.stack_circle{
    border: #F1DAC4 4px solid;
    border-radius: 50%;
    position: absolute;
    width: 750px;
    height: 750px;
    bottom: -470px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 1;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.3s;
}

.stack_circle.notLoaded{
  opacity: 0;
}

.stack_wrapper{
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background: #F1DAC4;
    display: grid;
    place-items: center;
    position: absolute;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 3;
}

.stack_wrapper_holder{
  width: 80px;
  height: 80px;
  position: absolute;
  background: #0D0C1D;
  border-radius: 50%;
}

.stack_wrapper:after{
  z-index: 1;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;

}

.stack_wrapper:hover img{
  transform: scale(1.15);
}


.stack_wrapper > img{
    width: 40px;
    transition: all 0.2s ease-in-out;
}

#cpp{
  width: 60px;
}


@media screen and (max-width: 900px){
  .Title,
  #Position{
    transform: scale(0.8);
  }
  .skills_wrapper>p{
    font-size: 16px;
  }
  .jupiter_picture{
    width: 450px;
    height: 450px;
    transform: translateY(200px);
  }

  .stack_circle{
    bottom: -320px;
    width: 600px;
    height: 600px;
    bottom: -340px;
  }

  .stack_wrapper,
  .stack_wrapper_holder{
    transform: scale(0.9) !important;
  }
}

@media screen and (max-width: 760px){
  .wrapper:after{
    height: calc(100% - 50px);
  }

  .figure_space{
    min-height: 600px;
  }

  .jupiter_picture{
    width: 250px;
    height: 250px;
    transform: translateY(150px);
  }
  
  #tooltip{
    font-size: 12px;
  }

  .stack_circle{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 350px;
    height: 350px;
    transform: translateY(-25px);
  }

  .stack_wrapper,
  .stack_wrapper_holder{
    transform: scale(0.7) !important;
  }
} 

@media screen and (max-width: 660px){
  .learn_btn{
    left: 0;
    right: 0;
    margin: auto;
    width: 72px;
  }
  .Title{
    font-size: 22px;
    margin-top: 20px;
  }
  #landing_text_wrapper{
    padding-top: 100px;
  }
  .skills_wrapper{
    width: 300px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}


@media screen and (max-width: 550px){
  .wrapper{
    padding: 10px;
    min-height: calc(100vh - 20px);
  }

  .wrapper:after{
    width: calc(100% - 30px);
    height: calc(100% - 18px);
    margin: 5px auto;
  }
  
  .figure_space{
    min-height: 400px;
  }
  .learn_btn{
    font-size: 14px;
    width: 70px;
    text-align: center;
  }
  .Title{
    text-align: center;
    font-size: 24px;
  }
  .Position{
    font-size: 14px;
  }

  .skills_wrapper{
    width: 250px;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  }
  .jupiter_picture{
    width: 200px;
    height: 200px;
    transform: translateY(85px) translateX(-5px);
  }


  .stack_circle{
    width: 240px;
    height: 240px;
    transform: translateY(-15px) translateX(-5px);
  }
} 
