.notfound-wrapper {
    width: calc(100dvw - 50px);
    height: calc(100dvh - 50px);

    background: url("../Images/NotFound/bg.webp");
    background-size: cover;
    background-position: center;

    font-family: "IBM Plex Mono", monospace;
    display: grid;
    place-content: center;
    padding: 25px;
}

.notfound-text-wrapper:after {
    position: absolute;
    content: "";
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    margin: 25px auto;
    border: #f1dac4 4px solid;
}

.notfound-text-wrapper {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #100f1ba2;
    color: #f1dac4;
    z-index: 3;
}

.notfound-text-h1,
.notfound-text-h3 {
    margin-bottom: 20px;
}

.notfound-text-h1,
.notfound-text-h3 {
    max-width: 700px;
    min-width: 250px;
    width: 80%;
    text-align: center;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.notfound-text-h1.notLoaded,
.notfound-text-h3.notLoaded {
    opacity: 0;
    transform: translateY(100px);
}

.notfound-text-button {
    font-family: "IBM Plex Mono", monospace;
    padding: 8px 18px;
    font-weight: bold;
    background: #f1dac4;
    color: #100f1b;
    cursor: pointer;
    border: 2px transparent solid;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.notfound-text-wrapper button{
        font-family: "IBM Plex Mono", monospace;
    padding: 8px 18px;
    font-weight: bold;
    background: #f1dac4;
    color: #100f1b;
    cursor: pointer;
    border: 2px transparent solid;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.notfound-text-button:hover {
    border-color: #f1dac4;
    background: #100f1b;
    color: #f1dac4;
}

.notfound-text-button.notLoaded{
    opacity: 0;
    transform: translateY(100px);
}

@media screen and (max-width: 600px) {
    .notfound-text-h1 {
        font-size: 26px;
    }

    .notfound-text-h3 {
        font-size: 16px;
    }
}