.cpp-wrapper{
    background-image: linear-gradient(to right top, #0d0c1d, #100f1b, #121119, #141317, #151515);
    width: auto;
    min-height: calc(100vh - 58px);
    height: auto;
    padding: 29px;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns: 300px 1fr;
}

.cpp-wrapper:after{
  position: absolute;
  content: '';
  width: calc(100% - 58px);
  height: calc(100% - 58px);
  left: 0;
  right: 0;
  top: 0;
  margin: 25px auto;
  border: #F1DAC4 4px solid;
}

.cpp-particles_background{
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
}

.cpp-introduction{
  color: #FFF;
  font-family: 'IBM Plex Mono', monospace;
  padding: 20px;
}

.cpp-introduction span {
  cursor: pointer;
  border-bottom: 2px #F1DAC4 solid;
  transition: all 0.2s ease-in-out;
}

.cpp-introduction span:hover {
  color: #F1DAC4;
}

.cpp-program_wrapper{
  padding: 10px;
  background: #100f1b;
  z-index: 3;
  display: grid;
  grid-template-rows: auto 1fr;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.cpp-program_wrapper.notLoaded{
  opacity: 0;
}

.cpp-copy_btn{
  color: #FFF;
  font-family: 'IBM Plex Mono', monospace;
  background: #F1DAC4;
  padding: 8px 18px;
  margin: 0 0 0 auto;
  color: #100f1b;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: transparent 2px solid;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.cpp-copy_btn:hover{
  color: #F1DAC4;
  border-color: #F1DAC4;
  background: #100f1b;
}

.cpp-copy_btn.notLoaded{
  opacity: 0;
}

.language-cpp{
  color: #FFF;
  opacity: 1;
  transform: translateY(0);
  padding-top: 0 !important;
  transition: all 0.3s ease-in-out;
}

.language-cpp.notLoaded{
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.3s ease-in-out;
}

.cpp-program_wrapper pre,
.cpp-program_wrapper pre > code {
    background-color: transparent !important; /* Make the background of the code transparent */
}

@media screen and (max-width: 1100px){
  .cpp-wrapper{
    grid-template-columns:1fr;
  }

  .language-cpp::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .language-cpp::-webkit-scrollbar-thumb {
    background-color: #F1DAC4;
  }

  .language-cpp::-webkit-scrollbar-thumb:hover {
    background-color: #F1DAC4;
  }
}



@media screen and (max-width: 760px){
  .cpp-wrapper{
    padding: 10px;
    min-height: calc(100vh - 20px);
  }

  .cpp-wrapper:after{
    width: calc(100% - 18px);
    height: calc(100% - 18px);
    margin: 5px auto;
  }
}
