#react-calculator-wrapper {
  width: 300px;
  height: 400px;
  background: #161B33;
  border: 2px #F1DAC4 solid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-template-areas:
    "display display display display"
    "clear clear divide multiply"
    "seven eight nine subtract"
    "four five six add"
    "one two three equals"
    "zero zero decimal equals";
  padding: 5px;
  grid-gap: 2px;
  justify-self: center;
  align-self: start;
}

#react-calculator-display {
  grid-area: display;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 20px;
  font-family: "digital";
  color: white;
  text-align: right;
}
#react-calculator-display > p {
  margin: 0;
}

#react-calculator-input {
  color: #F1DAC4;
}

#react-calculator-zero {
  grid-area: zero;
}
#react-calculator-one {
  grid-area: one;
}
#react-calculator-two {
  grid-area: two;
}
#react-calculator-three {
  grid-area: three;
}
#react-calculator-four {
  grid-area: four;
}
#react-calculator-five {
  grid-area: five;
}
#react-calculator-six {
  grid-area: six;
}
#react-calculator-seven {
  grid-area: seven;
}
#react-calculator-eight {
  grid-area: eight;
}
#react-calculator-nine {
  grid-area: nine;
}
#react-calculator-decimal {
  grid-area: decimal;
}
#react-calculator-equals {
  grid-area: equals;
  background-color: #F1DAC4;
}
#react-calculator-clear {
  grid-area: clear;
  background-color: #f5253d;
}
#react-calculator-add {
  grid-area: add;
  background-color: #A69CAC;
}
#react-calculator-multiply {
  grid-area: multiply;
  background-color: #A69CAC;
}
#react-calculator-divide {
  grid-area: divide;
  background-color: #A69CAC;
}
#react-calculator-subtract {
  grid-area: subtract;
  background-color: #A69CAC;
}

.react-calculator-button {
  padding: 10px;
  border: none;
  background: #474973;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.react-calculator-button:hover {
  border: 2px #0D0C1D solid;
  color: #0D0C1D;
  cursor: pointer;
}


@media screen and (max-width: 450px){
  #react-calculator-wrapper {
    width: 250px;
  }
}