.about-wrapper{
    background-image: linear-gradient(to right top, #0d0c1d, #100f1b, #121119, #141317, #151515);
    width: auto;
    min-height: calc(100vh - 100px);
    height: auto;
    padding: 50px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.about-wrapper:after{
  position: absolute;
  content: '';
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  left: 0;
  right: 0;
  top: 0;
  margin: 25px auto;
  border: #F1DAC4 4px solid;
}

.about-particles_background{
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
}

.about-saturn_img{
    position: absolute;
    z-index: 2;
    left: -250px;
    bottom: -50px;
    opacity: 1;
    transform: rotate(20deg) scale(0.7) translateY(0);
    transition: all 0.6s ease-in-out;
}

.about-saturn_img.notLoaded{
    opacity: 0;
    transform: translateY(100px);
}

.about-name{
    text-align: center;
    color: #F1DAC4;
    font-family: 'IBM Plex Mono', monospace;
}

.about-social_media_wrapper{
    z-index: 3;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 250px;
    margin: 20px auto;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.about-social_media_wrapper.notLoaded{
    opacity: 0;
}

.about-social_media_wrapper img{
    width: auto;
    height: 40px;
    cursor: pointer;
    border: 2px #FFF solid;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.about-social_media_wrapper img:hover{
    transform: scale(0.9);
}

.about-paragraph{
    color: #FFF;
    font-family: 'IBM Plex Mono', monospace;
    text-align: center;
    max-width: 800px;
    min-width: 300px;
    width: 80%;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    z-index: 3;
}

.about-paragraph.notLoaded{
    opacity: 0;
}

.about-certificates_wrapper{
    width: 80%;
    max-width: 400px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px auto;
    z-index: 3;
}

.about-certificates_title{
    color: #FFF;
    font-size: 18px;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.about-certificates_title.notLoaded{
    opacity: 0;
}

.about-certificate_wrapper{
    color: #0d0c1d;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 16px;
    background: #F1DAC4;
    width: calc(100% - 36px);
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 20px;
    align-items: center;
    place-content: start;
    padding: 12px 18px;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
}

.about-certificate_wrapper img{
    width: auto;
    height: 40px;
}

.about-certificate_wrapper.notLoaded{
    opacity: 0;
    transform: translateY(100px);
}

.about-certificate_wrapper:hover{
    transform: scale(1.05);
}

@media screen and (max-width: 750px){
    .about-saturn_img{
        left: -350px;
        bottom: -100px;
    }
}
@media screen and (max-width: 500px){
    .about-wrapper{
        min-height: calc(100vh - 50px);
        height: auto;
        padding: 25px;
    }
    .about-name{
        margin-top: 20px;
        font-size: 24px;
    }
    .about-social_media_wrapper img{
        height: 30px;
    }
    .about-paragraph{
        font-size: 14px;
    }
    .about-wrapper:after{
      width: calc(100% - 25px);
      height: calc(100% - 25px);
      margin: 12px auto;
    }
    .about-saturn_img{
        left: -350px;
        bottom: -100px;
    }

    .about-certificates_title{
        font-size: 16px;
    }

    .about-certificates_wrapper{
        grid-gap: 10px;
    }

    .about-certificate_wrapper{
        width: calc(100% - 24px);
        font-size: 16px;
        grid-template-columns: 40px 1fr;
        grid-gap: 10px;
        padding: 8px 12px;
    }
    
    .about-certificate_wrapper img{
        width: auto;
        height: 30px;
    }
    
    
}