#barchart-wrapper {
  width: auto;
  height: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 30px 10px;
  color: #fff;
}

#barchart-title {
  font-size: 26px;
}

#barchart-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #fff;
  border: 1px #000 solid;
  border-radius: 10px;
  position: absolute;
}

.barchart-visHolder{
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 1280px){
  .barchart-visHolder{
    transform: scale(0.8) translateX(-100px) translateY(-50px);
  }
}
@media screen and (max-width: 1100px){
  .barchart-visHolder{
    transform: scale(0.9) translateX(-50px) translateY(0);
  }
}
@media screen and (max-width: 870px){
  .barchart-visHolder{
    transform: scale(0.7) translateX(-200px) translateY(-50px);
  }
}
@media screen and (max-width: 700px){
  #barchart-wrapper{
    min-height: 800px;
  }
  .barchart-visHolder{
    transform: rotate(90deg) scale(0.8) translateX(180px) translateY(330px);
  }
}

@media screen and (max-width: 500px){
  #barchart-wrapper{
    min-height: 650px;
  }
  .barchart-visHolder{
    transform: rotate(90deg) scale(0.7) translateX(130px) translateY(450px);
  }
}
