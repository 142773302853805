.react-drum-machine-body {
  width: auto;
  height: auto;
  background: #f1dac4;
  padding: 20px;
  display: flex;
  border: 2px #f1dac4 solid;
  justify-self: center;
  align-self: start;
}

#react-drum-machine-drum-pads-wrapper {
  width: 320px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;
}

.react-drum-machine-drum-pad {
  background: #161b33;
  color: #f1dac4;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 1px black solid;
  transition: background-color 0.3s, transform 0.3s;
}

.react-drum-machine-drum-pad-clicked {
  background: #474973;
  scale: 0.95;
}

#react-drum-machine-display {
  width: 100px;
  height: 20px;
  text-align: center;
  align-self: center;
  justify-self: center;
  background: #161b33;
  border: 1px #000000 solid;
  color: #fff;
  padding: 10px 20px;
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .react-drum-machine-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 450px) {
  #react-drum-machine-drum-pads-wrapper {
    width: 250px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(76px, 1fr));
    grid-gap: 10px;
  }
}